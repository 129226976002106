import { Component, HostListener, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent, Event } from '@angular/router';

import { AnalyticsService } from '@app/services/analytics.service';
import { Title } from '@angular/platform-browser';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { NavigatorService } from '@app/core/navigator.service';
import { takeUntil } from 'rxjs';
import { AppRouteStatus } from '@app/models/shared/AppRouteStatus';

@Component({
  selector: 'app-layout',
  styleUrls: ['./layout.component.scss'],
  templateUrl: './layout.component.html',
})
export class LayoutComponent extends AndroWebCoreComponent implements OnDestroy {
  public isLoading: boolean;
  public isOnMenuPage: boolean = true;
  public isOnHomePage: boolean;

  constructor(
    private router: Router,
    private titleService: Title,
    private analyticsService: AnalyticsService,
    private _navigatorService: NavigatorService
  ) {
    super();
    this.trackRouterEvents();
  }

  /**
   * makes the categories bar on the menu sticky.
   */
  @HostListener('window:scroll')
  public handleScroll(): void {
    if (this.isOnMenuPage) {
      const header: HTMLElement = document.getElementById('categories-bar');
      const headerHeight: number = 45;

      if (this.getWindowScrollPosition() > headerHeight) {
        header?.classList.add('sticky');
      } else {
        header?.classList.remove('sticky');
      }
    }
  }

  /**
   * tracks the page and title for every NavigationEnd router even and additionally updates `isLoading` between routeConfigLoad events
   */
  private trackRouterEvents(): void {
    this._navigatorService.status$
        .pipe(takeUntil(this.destroy$))
        .subscribe((status: AppRouteStatus) => {
          this.isOnMenuPage = status.isMenuPage;
          this.isOnHomePage = status.isHomePage;
        });

    this.router.events.forEach((event: Event | RouterEvent) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }

      if (event instanceof NavigationEnd) {
        const container = document.getElementById('content');
        if (container) {
          container.scrollTop = 0;
        }
        this.analyticsService.pageView(event.urlAfterRedirects, this.titleService.getTitle());
      } else if (event instanceof NavigationError) {
        this.analyticsService.trackException(event.error, true);
      }
    });
  }


  /**
   * returns the window scroll y position. this is only separate function so {@link handleScroll} could be better tested.
   */
  private getWindowScrollPosition(): number {
    return window.scrollY;
  }
}
